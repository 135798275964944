/** @jsx jsx */
import { Field, Form, withFormik } from 'formik';
import { getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { Box, jsx, Label } from 'theme-ui';
import { v4 as uuid } from 'uuid';
import * as yup from 'yup';
import Button from '../components/Button';
import CheckboxField from '../components/CheckboxField';
import DropzoneField from '../components/DropzoneField';
import FormField from '../components/FormField';
import RadioField from '../components/RadioField';
import RadioFieldGroup from '../components/RadioFieldGroup';
import { getLocale } from '../state/session';

const phoneNumberRegExp = /^\+?[0-9\s]{6,18}$/;
const eoriNumberRegExp = /^FI[0-9]{7}-[0-9]{1}$/;
const ssnRegExp = /^\d{6}[A+-]\d{3}[a-zA-Z0-9]$/;

const mandateDetailsSchema = translate =>
  yup.object().shape({
    phoneNumber: yup
      .string()
      .required(translate('register.message.requiredField'))
      .matches(phoneNumberRegExp, translate('register.message.invalidPhoneNumber')),
    senderId: yup.string().required(translate('form.requiredField')),
    email: yup
      .string()
      .required(translate('form.requiredField'))
      .email(translate('form.invalidEmail')),
    shipmentNumber: yup.string().required(translate('form.requiredField')),
    senderType: yup
      .string()
      .oneOf(['person', 'company'], translate('feedback.message.invalidType'))
      .required(translate('feedback.message.requiredField')),
    attachment: yup
      .array()
      .of(yup.mixed())
      .max(2, translate('feedback.message.tooManyFiles', { files: 2 }))
      .ensure(),
    confirmation: yup
      .bool()
      .oneOf([true], translate('customsFlow.message.requiredField'))
      .required(translate('customsFlow.message.requiredField')),

    customerId: yup.string(),
  });

export const MandateDetailsForm = ({ values, handleSubmit, isSubmitting }) => {
  const {
    parcel: { parcelNumber },
  } = useSelector(state => state.tracking);

  const translate = getTranslate(useSelector(state => state.localize));

  return (
    <Form sx={{ maxWidth: 640 }}>
      <Box sx={{ width: '50%' }}>
        <FormField label={translate('customsFlow.mandateMatkahuolto.shipmentNumber')} name="shipmentNumber" />
      </Box>

      <RadioFieldGroup id="senderType" label={translate('customsFlow.mandateMatkahuolto.deliveryRecipient')}>
        <Field
          component={RadioField}
          name="senderType"
          id="person"
          label={translate('customsFlow.mandateMatkahuolto.mandatePrivate')}
        />
        {values.senderType === 'person' && (
          <Box
            sx={{
              pl: 4,
              display: 'grid',

              gridTemplateColumns: '1fr',
            }}
          >
            <FormField label={translate('customsFlow.mandateMatkahuolto.ssn')} name="senderId" />
          </Box>
        )}
        <Field
          component={RadioField}
          name="senderType"
          id="company"
          label={translate('customsFlow.mandateMatkahuolto.mandateCorporate')}
        />
        {values.senderType === 'company' && (
          <Box
            sx={{
              pl: 4,
              display: 'grid',
              gridTemplateColumns: '1fr',
            }}
          >
            <FormField label={translate('customsFlow.mandateMatkahuolto.eori')} name="senderId" />
          </Box>
        )}
      </RadioFieldGroup>
      <FormField label={translate('customsFlow.mandateMatkahuolto.email')} name="email" />
      <FormField label={translate('customsFlow.mandateMatkahuolto.phoneNumber')} name="phoneNumber" />
      <Box sx={{ pl: 4, mb: 3 }}>
        <DropzoneField
          name="attachment"
          label={translate('customsFlow.mandateMatkahuolto.attachment')}
          extraLabel={translate('customsFlow.mandateMatkahuolto.requiredWhen')}
          placeholder={translate('customsFlow.mandateMatkahuolto.dropzoneText')}
          clearButtonText={translate('customsFlow.mandateMatkahuolto.clear')}
          zoneActiveText={translate('customsFlow.mandateMatkahuolto.dropzoneActiveText')}
          sx={{ cursor: 'pointer' }}
        />
        <Field
          component={CheckboxField}
          name="confirmation"
          id="confirmation"
          // dangerouslySetInnerHTML={{
          //   __html: translate('customsFlow.mandateMatkahuolto.convince'),
          // }}
          link={{
            target: 'https://www.matkahuolto.fi/paketit/laheta-paketti-verkkokaupan-ehdot',
            text: translate('customsFlow.mandateMatkahuolto.terms'),
          }}
          label={translate('customsFlow.mandateMatkahuolto.convince')}
          sx={{ mb: 3 }}
          // error={errors.accuracy}
        />
      </Box>
      <Button style={{ alignSelf: 'flex-end' }} type="submit" onClick={handleSubmit} disabled={isSubmitting}>
        {translate('account.saveButton')}
      </Button>
    </Form>
  );
};

export const MandateDetailsFormik = withFormik({
  mapPropsToValues: ({
    user,
    parcelNumber,
    mandateDetails: { email, senderId, senderType, phoneNumber, customerId },
  }) =>
    Object.assign({
      language: getLocale(user) || 'fi',
      shipmentNumber: parcelNumber || '',
      senderType: senderType || '',
      senderId: senderId || '',
      email: (user && user.email) || email || '',
      confirmation: false,
      phoneNumber: (user && user.phoneNumber) || phoneNumber || '',
      attachment: [],
      customerId: customerId || '',
    }),
  validationSchema: ({ translate }) => {
    return mandateDetailsSchema(translate);
  },
  handleSubmit: (values, { props: { onSubmit }, ...actions }) => {
    if (!values.customerId) values.customerId = uuid();
    onSubmit(values, actions);
  },
  displayName: 'MandateDetailsInputForm',
})(MandateDetailsForm);
